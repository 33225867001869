import React from 'react';
import { Link } from 'react-router-dom';
import TimeLineTable from '../components/TimeLineTable'

export default function TimelinePage() {
    
    const timeLineData1 = [
        {   date:   `Ende 2003`, 
            note:   `Eigentlich ist es ziemlich schwierig zu sagen, wann alles begonnen hat.
                    Natürlich stellt man sich im Nachhinein oft genug die Frage, warum man 'es' nicht früher gemerkt hat und dann alles nicht so schlimm gekommen wäre.
                    Wenn ich aber jetzt zurückblicke, muß ich zugeben, daß ich durchaus hin und wieder Probleme hatte;
                    mein Pech, daß sie nicht so schlimm waren, als daß man sich deswegen Sorgen machen müßte.
                    Die Wehwehchen vergingen so schnell, wie sie gekommen waren.
                    Noch dazu die Weihnachtszeit mit den ganzen Feiern: vielleicht war die Gans etwas zu fett und die Portion Sauerbraten etwas zu groß gewesen?!?
                    Auf alle Fälle habe ich bis unmittelbar vor Weihnachten gearbeitet und freute mich auf 2 Wochen Urlaub.`,
        },
        {   date:   `Sonntag 28.12.2003`,
            note:   `Relativ starke Bauchschmerzen; frage meinen Vater, auf welcher Seite der Blinddarm sitzt.`,
        },
        {   date:   `Montag 29.12.2003`,
            note:   `Helfe bei Baumfällarbeiten, muß aber später wegen der Schmerzen abbrechen.       
                     Bekomme abends einen sichtbar geschwollenen Bauch und leichtes Fieber.`,
        },
        {   date:   `Dienstag 30.12.2003`,
            note:   `Bauch schwillt immer weiter an. Alles zieht und spannt und schmerzt bei der kleinsten Bewegung.
                     Kann nicht schlafen.`,
        },
        {   date:   `Mittwoch 31.12.2003`,
            note:   `Bauch ist zum Platzen gespannt, ein unbeschreibliches Gefühl.
                     Habe starke Schweißausbrüche.
                     Kann nicht mehr liegen/stehen/sitzen.
                     Kann nicht schlafen.
                     Achja, noch ein gutes neues Jahr!!!!`,
        },
        {   date:   `Donnerstag 01.01.2004`,
            note:   `Bin beim Hausarzt der erste, der die 10,-€ zahlt.
                     Weiterhin starke Schweißausbrüche.
                     An Schlaf ist nicht zu denken.`,
        },
        {   date:   `Freitag 02.01.2004`,
            note:   `Ein Internist überweist mich nach einer Ultraschalluntersuchung, bei der er sehr viel Flüssigkeit in der Bauchhöhle findet, ins Kreiskrankenhaus Eichstätt.
                     Dort läßt man 6,5L !!! Ascites ablaufen. Noch heute denke ich manchmal, wenn ich im Getränkemarkt solche 5L-Partyfässer sehe, daß ich sowas noch etwas größer in mir drin hatte.
                     Endlich Besserung.`,
        },
        {   date:   `Samstag 03.01.2004`,
            note:   `Bauch beginnt sich schnell wieder mit Flüssigkeit zu füllen.
                     Ärzte äußern ersten Verdacht auf schweren Leberschaden, vielleicht eine Zirrhose??`,
        },
        {   date:   `Sonntag 04.01.2004`,
            note:   `Man bekommt diesen Ascites nicht in den Griff ==> habe wieder Bauchschmerzen.
                     Um zu verhindern, daß sich der Ascites so schnell nachbildet, darf ich pro Tag nur noch 1,5L Flüssigkeit zu mir nehmen. Das ist nicht viel, wenn man nach wie vor starke Schweißausbrüche hat und kaum schlafen kann. Um mich zu erfrischen, setze ich mich ab und an ans Waschbecken und lasse über meine Handgelenke kühles Wasser laufen.
                     Kann wieder kaum Schlafen.`,
        },
        {   date:   ` Montag 05.01.2004`,
            note:   `Wegen des Verdachts auf Leberschaden bekomme ich keine Schmerz- und Schlaftabletten.
                     Nierenwerte verschlechtern sich auch zusehends.
                     Zur Beobachtung komme ich auf die Intensivstation.
                     Bekomme einen Blasenkatheter gelegt ==> sehr unangenehm!!`,
        },
        {   date:   `Dienstag 06.01.2004`,
            note:   `Bleibe weiter zur Beobachtung auf der Intensivstation.
                     Der Ascites drückt schön langsam durchs Gewebe nach unten durch: ich habe Wasser in den Beinen und was es da sonst nocht gibt.`,
        },
        {   date:   `Mittwoch 07.01.2004`,
            note:   `Weiterhin Intensivstation.
                     In der Nacht dreht der Mann neben mir voll durch: Wahnsinn, sowas hab ich nicht für möglich gehalten.`,
        },
        {   date:   `Donnerstag 08.01.2004`,
            note:   `Komme Gott sei Dank wieder auf die normale Station.`,
        },
        {   date:   `08.01.2004 bis 11.01.2004`,
            note:   `Es werden unterschiedliche Untersuchungen gemacht, z.B. eine Magenspiegelung.`,
        },
        {   date:   `Montag 12.01.2004`,
            note:   `Man führt eine Koloskopie (Darmspiegelung) durch ==> sehr unangenehm; dabei entdeckt man ein entzündetes Gefäß und nimmt eine Gewebeprobe.
                     Kann nach wie vor kaum schlafen.`,
        },
        {   date:   `Dienstag 13.01.2004`,
            note:   `Endlich eine kleine Pause von den Untersuchungen.
                     Werde zum Urologen geschickt, aber auch ohne Ergebnis.
                     Bin inzwischen so schwach, daß ich mich zum Zähneputzen setzen muß!!
                     Kaum Schlaf.`,
        },
        {   date:   `Mittwoch 14.01.2004`,
            note:   `Eine Ärztin kommt nachmittags ins Zimmer und erklärt mir, daß ein aggressiver Tumor mit der Gewebeprobe bei der Koloskopie am 12.01. gefunden wurde. Sie verläßt aber schnell wieder das Zimmer, so daß ich erst mal dortsitze und überlege: hat sie jetzt aggressiv oder nicht aggressiv gesagt???
                     Bin total platt, mit sowas hab ich absolut nicht gerechnet.
                     Ärzte versuchen, ein Bett in München zu organisieren.`,
        },
        {   date:   `Donnerstag 15.01.2004`,
            note:   `Werde mit einem Krankentransport nach München ins Klinikum Rechts der Isar auf die Station 3/4 gebracht.
                     Bin so ko, daß ich im Rollstuhl gefahren werden muß.
                     Auf der Station wird ein ZVK am Hals gelegt.
                     Auf der Station wird im Krankenbett eine Knochenmarkspunktion durchgeführt.
                     Lt. Blutwerte stehe ich kurz vorm Nierenversagen.`,
        },
        {   date:   `Freitag 16.01.2004`,
            note:   `Der Ascites wird nochmals punktiert und 3 Liter abgelassen.
                     Es wird sofort mit einer Vorchemotherapie begonnen. Wer hätte das gedacht: vor 2 Wochen wußte ich nur schemenhaft über Chemotherapie Bescheid und jetzt bekomme ich selber eine! Auf alle Fälle macht die Chemo noch keine zusätzlichen Probleme.
                     Endlich darf ich wieder essen und trinken soviel ich will.`,
        },
        {   date:   `Samstag 17.01.2004`,
            note:   `Am 2.Tag Vorchemo weiterhin keine Probleme.
                     Appetit ist gut.
                     Kann wieder besser schlafen.`,
        },
        {   date:   `Montag 19.01.2004`,
            note:   `Am 4.Tag Vorchemo weiterhin keine Probleme.
                     Ein Professor hat mich gebeten, in seine Vorlesung zu kommen und meinen Fall vor Studenten zu schildern; war echt sehr interressant.`,
        },
        {   date:   `Dienstag 20.01.2004`,
            note:   `Am 5. und letzten Tag Vorchemo: keine Probleme.
                     Alle Werte bessern sich.`,
        },
        {   date:   `Mittwoch 21.01.2004`,
            note:   `Es wird nochmals 2,5 Liter Ascites abgelassen. Leider mußte 3mal dafür gestochen werden.`,
        },
        {   date:   `Donnerstag 22.01.2004`,
            note:   `Die 1. Chemotherapie beginnt mit Antikörpern: ich bin sehr aufgeregt, weil ich nicht genau weiß, was da alles auf mich zukommt.`,
        },
        {   date:   `Freitag 23.01.2004`,
            note:   `2. Tag Chemo: bin nach wie vor sehr aufgeregt, aber sonst ist alles ok.
                     Es wird im Krankenbett eine Punktion des unteren Rückenmarkskanals gemacht. Dabei wird sowohl eine Probe entnommen als auch ein Chemomittel gespritzt.
                     Habe am linken kleinen Finger eine Nagelbettentzündung: ist mal wieder typisch; sowas hatte ich noch nie und gerade jetzt, wo durch die Chemo das Immunsystem geschwächt wird, passiert sowas.`,
        },
        {   date:   `Samstag 24.01.2004`,
            note:   `3. Tag Chemo: leichtes Hitzegefühl, aber sonst ok.
                     Appetit ist gut.
                     Ich bekomme die Nachricht, daß im Knochenmark von der Untersuchung am 15.01. nichts gefunden wurde ==> Gott sei Dank.`,
        },
        {   date:   `Sonntag 25.01.2004`,
            note:   `4. Tag Chemo: alles ok.
                     Appetit bleibt gut.
                     In der Nacht kein Schweißausbruch mehr.
                     Die vielen Schwerkranken um mich herum bedrücken mich. Ich weiß oft nicht, was ich sagen soll.`,
        },
        {   date:   `Montag 26.01.2004`,
            note:   `5. Tag Chemo: werde kribbelig/nervös.
                     In der Nacht kein Schweißausbruch mehr.
                     Bekomme vorbeugend wg. der starken Chemo Augentropfen.
                     90,1kg / 91,4kg`,
        },
        {   date:   `Dienstag 27.01.2004`,
            note:   `6. und letzter Chemo-Tag vom 1. Zyklus: bin kribbelig/nervös.
                     In der Nacht kein Schweißausbruch mehr.
                     Bekomme weiterhin Augentropfen.
                     Bekomme die Nachricht, daß auch im Liquor vom 23.01. keine Krebszellen gefunden wurden.
                     Wieder wird ein Chemomittel in den unteren Rückenmarkskanal gespritzt.
                     89,7kg / 89,8kg`,
        },
        {   date:   `Mittwoch 28.01.2004`,
            note:   `1. Block Chemo ist gestern ausgelaufen: habe noch keine Beschwerden.
                     Bekomme keine Infusionen mehr.
                     Leicht erhöhte Temperatur.
                     1. Aufbauspritze für Blutzellen (Neupogen).
                     Ernährungsberaterin hat mich besucht; ich dachte schon, daß ich auf Diät gesetzt werde, weil ich soviel gegessen habe ;-) aber im Gegenteil; sie meinte, ich solle soviel und solange essen, wie ich könne.
                     88,1 kg / 88,6kg`,
        }, 
        {   date:   `Donnerstag 29.01.2004`,
            note:   `In der Nacht ging's bergab: leichte Muskelschmerzen und Unwohlsein.
                     Tagsüber gings wieder besser.
                     Habe ein Konzert in der Krankenhauskapelle besucht.
                     Blutwerte sind noch besser als gedacht: lasse mir deshalb von meinem Bruder was von McDonald's mitbringen.
                     Die Entzündung am kleinen Finger macht mir Sorgen; sie wird immer schlimmer.
                     86,2kg / 85,6kg`,
        }, 
        {   date:   `Freitag 30.01.2004`,
            note:   `Habe die Nacht einigermaßen überstanden.
                     Die Entzündung am kleinen Finger muß in der chirurgischen Abteilung aufgeschnitten und behandelt werden. Dazu wird der Finger betäubt. Habe zwar keine Schmerzen, aber die gesamte Hand wird mit dem Unterarm ruhiggestellt und eingebunden.
                     82,6kg / 82,7kg`,
        }, 
        {   date:   `Samstag 31.01.2004`,
            note:   `Schlecht geschlafen.
                     Sonst geht's gut.
                     Warten auf den "Abschwung" der Blutwerte.
                     80,6kg / 81,1kg`,
        }, 
        {   date:   ` Sonntag 01.02.2004`,
            note:   `Nur noch 800 Leukos ==> neutropene Phase beginnt.
                     Leichter Husten.
                     Bekomme Antibiotika wg. Finger und Husten.
                     Ab heute keimreduzierte Kost wg. neutropener Phase.
                     79,1kg / 80,1kg`,
        }, 
        {   date:   ` Montag 02.02.2004`,
            note:   `Nur noch 400 Leukos.
                     Habe leichtes Nasenbluten.
                     Entzündung am Finger nach wie vor beschissen.
                     79,1kg / 81,5kg`,
        }, 
        {   date:   `Dienstag 03.02.2004`,
            note:   `400 Leukos.
                     Leichter Herpes.
                     Zahnfleisch schwillt leicht an.
                     Finger wird etwas besser.
                     80,6kg / 82,7kg`,
        }, 
        {   date:   `Mittwoch 04.02.2004`,
            note:   `Soweit geht's ganz gut.
                     Leukos scheinen wieder zu steigen: sind wieder bei 820.
                     Finger wird auch besser, auch wenn er wirklich noch schlimm ausschaut.
                     82,7kg / 83,1kg`,
        }, 
        {   date:   ` Donnerstag 05.02.2004`,
            note:   `Leider kein Bluttest.
                     82,3kg / 82,5kg`,
        }, 
        {   date:   `Freitag 06.02.2004`,
            note:   `Leukos sind bei 22.000!!!! ==> neutropene Phase ist vorbei.
                     Finger wird auch immer besser: ab heute muß ich nur noch alle 2 Tage zum Reinigen der Wunde und neu verbinden.
                     Ultraschalluntersuchung vom Bauch: kein Ascites mehr zu finden.
                     82,5kg / -
                     ==> 1. Zyklus ist fertig.`,
        }, 
        {   date:   `Samstag 07.02.2004`,
            note:   `Darf zum 1. mal seit 02.01.04 heim: juheeee.`,
        }, 
        {   date:   `Montag 09.02.2004`,
            note:   `Beim Haarewaschen gehen die ersten Haare aus.
                     Der Antrag auf Feststellung einer Behinderung wird abgeschickt.`,
        }, 
        {   date:   `Mittwoch 11.02.2004`,
            note:   `Schon bin ich wieder auf der Station 3/4 in München.
                     Eine Schwester hat die Haare runtergeschnitten; naja, hat mich schon immer mal interressiert, wie ich dann aussehe :-)
                     Port wurde heute doch nicht implantiert.
                     - / 84,4kg`,
        }, 
        {   date:   `Donnerstag 12.02.2004`,
            note:   `Mit Ultraschall wird nochmals überprüft, ob sich wieder Ascites angesammelt hat: aber dem war nicht so!!
                     Finger verbessert sich weiter.
                     Von 1230 bis 1300 wird der Port implantiert; war vor der OP wahnsinnig aufgeregt. Der Chirurg war total locker: nebenbei lief das Radio und wir haben uns Witzchen erzählt. Habe nach der OP kaum Schmerzen, kann den Arm bewegen, muß aber vorsichtig sein.
                     Onkologen beginnen am selben Tag noch den 2. Chemozyklus mit Antikörpern, die ich auch gut vertrage.
                     82,0kg / 82,6kg`,
        }, 	
        {   date:   `Freitag 13.02.2004`,
            note:   `Finger heilt weiter ab.
                     2.Tag Chemo: Port wird zum 1.mal angestochen und ist einwandfrei durchgängig; leichtes Hitzegefühl, aber sonst ok.
                     Wieder Punktion des unteren Rückenmarkskanals, um Chemomittel einzuspritzen.
                     81,6kg / -`,
        },
        {   date:   `Samstag 14.02.2004`,
            note:   `Habe die Nacht gut überstanden.
                     3.Tag Chemo: soweit alles ok.
                     Bekomme Lasex wg. deutlicher Gewichtszunahme.
                     86,5kg / 87,0kg`,
        },	
        {   date:   `Sonntag 15.02.2004`,
            note:   `4.Tag Chemo: leichte Kopfschmerzen, Appetitlosigkeit.
                     Am Finger bekomme ich endlich die große Schiene abgenommen und durch eine kleine ersetzt.
                     Wieder leichtes Nasenbluten.
                     84,9kg / 86,9kg`,
        },
        {   date:   `Montag 16.02.2004`,
            note:   `Nacht gut überstanden.
                     5.Tag Chemo: leichte Kopf- und Rückenschmerzen.
                     Ich sehe nicht mehr so gut; wahrscheinlich eine Nebenwirkung.
                     86,9kg / 86,7kg`,
        }, 	
        {   date:   `Dienstag 17.02.2004`,
            note:   `6.Tag Chemo: leichte Kopfschmerzen, Appetitlosigkeit.
                     Wieder Punktion des unteren Rückenmarkskanals, um Chemomittel einzuspritzen.
                     85,8kg / 84,7kg`,
        },
        {   date:   `Mittwoch 18.02.2004`,
            note:   `Bin ziemlich müde und habe Kopf- und Knochenschmerzen.
                     Zahnfleisch ist ziemlich geschwollen.
                     Darf endlich weider heim!!
                     82,9kg / -`,
        },	
        {   date:   `Donnerstag 19.02.2004`,
            note:   `Nacht eigentlich gut überstanden.
                     Bekomme untertags starke Kopf- und Knochenschmerzen.
                     Bin völlig fertig; habe große Probleme mit dem Kreislauf, sobald ich vom Bett aufstehe.
                     Habe bei der Fahrt zum Doktor wg. Blutbild zum 1.mal erbrochen.
                     Mußte mir die Neupogenspritzen zum 1.mal selber setzen: da mußte ich schon ein paar mal ansetzen!!
                     Habe keinen Appetit.
                     Zahnfleisch ist stark geschwollen.`,
        }, 	
        {   date:   `Freitag 20.02.2004`,
            note:   `Starkes Erschöpfungsgefühl.
                     Blutbild ist lt. Doktor noch ok, mußte aber wieder erbrechen.
                     Starke Kopf- und Knochenschmerzen.
                     Wenigstens der Finger bessert sich weiter.
                     Kein Appetit.
                     Zahnfleisch ist stark angeschwollen.`,
        },
        {   date:   `Samstag 21.02.2004`,
            note:   `Endlich geht's besser: kann etwas essen; Kopfschmerzen lassen nach; Kreislauf stabilisiert sich; Zahnfleisch wird etwas besser.`,
        }, 	
        {   date:   `Sonntag 22.02.2004`,
            note:   `Habe zwar wieder Appetit, kann aber wg. dem geschwollenen Zahnfleisch nicht viel essen.`,
        },
        {   date:   `Montag 23.02.2004`,
            note:   `War beim Arzt wg. Blutbild: 2700 Leukos.
                     Zahnfleisch ist wieder sehr angeschwollen.`,
        },	
        {   date:   `Dienstag 24.02.2004`,
            note:   `Zahnfleisch ist so schmerzhaft geschwollen, daß ich nicht mehr essen kann; selbst Trinken und auch das Sprechen ist nur unter starken Schmerzen möglich.
                     Haben in München angerufen, um uns beraten zu lassen, was man da machen kann.`,
        },  
        {   date:   `Mittwoch 25.02.2004`,
            note:   `Mehr als 5000 Leukos.
            -        Zahnfleisch macht immer noch große Probleme: kann immer noch nix essen.`,
        },
        {   date:   `Donnerstag 26.02.2004`,
            note:   `Zahnfleisch bessert sich wieder langsam; die empfohlenen Mittel scheinen zu wirken.
                     Habe in den letzten Tage ca. 6-7kg abgenommen.
                     Werde sehr schnell müde.`,
        },	
        {   date:   `Freitag 27.02.2004`,
            note:   `Kann wieder essen, wenn auch mit Schmerzen.
                     Zahnfleisch bessert sich zusehends.
                     Blutbild ist auch wieder ok.
                     ==> 2. Zyklus ist fertig.`,
        },    
        {   date:   `Samstag 28.02.2004`,
            note:   `Es geht mir wieder besser.
                     Hab mit Freunden etwas Fußball in der Glotze angeschaut.`,
        },
        {   date:   `Sonntag 29.02.2004`,
            note:   `Zahnfleischprobleme sind nur noch minimal.
                     Bin nachmittags am Sportplatz gewesen und habe etwas unserer Mannschaft zugeschaut.`,
        },	
        {   date:   `Dienstag 02.03.2004`,
            note:   `Sehschärfe normalisiert sich wieder.`,
        },  
        {   date:   `Freitag 05.03.2004`,
            note:   `Bekomme im HTK (hämatologische Tagesklinik) in München ambulant die Antikörper und beginne damit den 3. Zyklus.
                     Das Zwischenstaging wird auch noch durchgeführt: Blutprobe und eine CT werden gemacht.
                     Bin durch die lange An- und Rückfahrt ziemlich müde.`,
        },
        {   date:   `Samstag 06.03.2004`,
            note:   `Abends mal ein komisches Gefühl; bleibe lieber daheim.`,
        },	
        {   date:   `Sonntag 07.03.2004`,
            note:   `Soweit alles wieder ok.
                     Habe an der Generalversammlung des Schützenvereins teilgenommen.`,
        },    
        {   date:   `Montag 08.03.2004`,
            note:   `Bin seit 0900 wieder auf Station 3/4.
                     3. Zyklus wird mit Chemotherapie weitergeführt.
                     War bei Augenärzten wegen der Sehstöhrung: an den Augen selber nix gefunden außer einer leichten Schiefstellung, die vermutlich von einem leicht entzündeten Muskel herrührt.
                     Befund vom Zwischenstaging vom 05.03.04: Lymphom ist optisch nicht mehr sichtbar!!
                     85,4kg / 85,8kg`,
        },
        {   date:   `Dienstag 09.03.2004`,
            note:   `Habe schlecht geschlafen.
                     Mittags verdrücke ich gleich 2 Portionen.
                     3.Tag Chemo.
                     Magnet-Resonanz-Untersuchung vom Kopf, um auszuschließen, daß Sehstöhrung von einem Tumor hervorgerufen wird.
                     84,9kg / 87,0kg`,
        },	
        {   date:   `Mittwoch 10.03.2004`,
            note:   `4.Tag Chemo: bekomme heute keine Chemo (nach Plan).
                     Untersuchung vom Kopf vom 09.03. zeigt, daß kein weiterer Tumor vorhanden ist: sowas hört man immer gern ;-)
                     86,1kg / 88,8kg`,
        }, 
        {   date:   `Donnerstag 11.03.2004`,
            note:   `5.Tag Chemo: komischer Geschmack im Mund.
                     Abends kein Appetit.
                     83,9kg / 85,9kg`,
        },       
        {   date:   `Freitag 12.03.2004`,
            note:   `6.Tag Chemo: brauche leichtes Abführmittel.
                     War beim Ruhe-EKG: Untersuchung vorgeschrieben für geplante Apherese.
                     84,5kg / - `,
        }, 
        {   date:   `Samstag 13.03.2004`,
            note:   `Schlecht geschlafen.
                     Heimfahrt.
                     Bin sehr müde.`,
        },
        {   date:   `Sonntag 14.03.2004`,
            note:   `Eine Nebenwirkung der Chemo: leide unter Verstopfung.`,
        }, 
        {   date:   `Montag 15.03.2004`,
            note:   `Habe diesmal nicht solche Probleme mit dem Kreislauf, kämpfe aber mit ständiger Müdigkeit.`,
        },     
        {   date:   `Mittwoch 17.03.2004`,
            note:   `Soweit geht's noch gut
                     Abends ist das Zahnfleisch wieder geschwollen; hoffe nur, daß es diesmal nicht so schlimm wird wie beim 2. Zyklus.`,
        }, 
        {   date:   `Donnerstag 18.03.2004`,
            note:   `Zahnfleisch beruhigt sich wieder, sonst auch alles ok.`,
        },       
        {   date:   `Freitag 19.03.2004`,
            note:   `Bin ständig müde.
                     1100 Leukos.
                     ==> 3. Zyklus ist fertig.`,
        }, 
        {   date:   `Sonntag 21.03.2004`,
            note:   `Bin ständig müde.
                     Starkes Hitzegefühl, habe aber kein Fieber.`,
        },
        {   date:   `Montag 22.03.2004`,
            note:   `Anreise nach München zur Apherese (Gewinnung von Stammzellen aus eigenem Blut): das ganze dauert 3,5h; ist nicht tragisch.`,
        }, 
        {   date:   `Dienstag 23.03.2004`,
            note:   `Von 0700 bis 0800 findet eine Magnet-Resonanz-Tomograph-Untersuchung für eine Studie statt.
                     Sonst ist alles ok, aber stinklangweilig.`,
        },  
        {   date:   `Mittwoch 24.03.2004`,
            note:   `Darf wieder heim.
                     Ich erfahre, daß auf die Bestrahlung zum Schluß verzichtet werden kann; hach ja, solche Tage müßte es öfters geben.`,
        },    
        {   date:   `Donnerstag 25.03.2004`,
            note:   `Habe mal bei den Arbeitskollegen zum Ratschen vorbeigeschaut.
                     Abends ein Weizen genossen!! (lecker!!).`,
        },  
        {   date:   `Samstag 27.03.2004`,
            note:   `Vormittags daheim mal wieder etwas nützlich gemacht.
                     Nachmittags etwas Fußball geglotzt.
                     Abends ein bißchen in einer Kneipe gesessen.`,
        },
        {   date:   `Sonntag 28.03.2004`,
            note:   `Nachmittags meinen Fußballern zugeschaut.`,
        },  
        {   date:   `Montag 29.03.2004`,
            note:   `Anfahrt zur 2. Halbzeit der Chemotherapie :-)
                     4. Zyklus Chemo beginnt wieder mit Antikörpern.
                     88,0kg / 88,2kg`,
        },
        {   date:   `Dienstag 30.03.2004`,
            note:   `Wieder Punktion des unteren Rückenmarkskanals, um Chemomittel einzuspritzen.
                     87,0kg / 87,7kg`,
        },  
        {   date:   `Mittwoch 31.03.2004`,
            note:   `3.Tag Chemo: leicht flaues Gefühl im Magen.
                     87,5kg / 88,1kg`,
        },
        {   date:   `Donnerstag 01.04.2004`,
            note:   `4.Tag Chemo: komischer Geschmack im Mund.
                     Bekomme wieder Lasex.
                     87,2kg / 89,5kg`,
        },  
        {   date:   `Freitag 02.04.2004`,
            note:   `5.Tag Chemo: Zunge ist leicht geschwollen.
                     87,4kg / 89,2kg`,
        },
        {   date:   `Samstag 03.04.2004`,
            note:   `6.Tag Chemo: ekelhafter chemischer Geschmack im Mund.
                     87,2kg / -`,
        },  
        {   date:   `Sonntag 04.04.2004`,
            note:   `Heimfahrt.`,
        },         	
        {   date:   `Montag 05.04.2004`,
            note:   `Soweit alles ok.
                     Meine Mutter ist total verschnupft; bin mal gespannt, wie lange das mit mir gutgeht!?!`,
        },  
        {   date:   `Donnerstag 08.04.2004 bis Sonntag 11.04.2004`,
            note:   `Werde schnell müde und ko, aber sonst alles ok.
                     Bekomme leicht Nasenbluten, nicht stark, aber hartnäckig.`,
        },         	
        {   date:   `Montag 12.04.2004`,
            note:   `Meine Temperatur kratzt den ganzen Tag um die 38°.
                     Schock am Abend: 38,8°!!
                     Mein Vater bringt mich ins KKH Eichstätt; dort werde ich auf eine Isolierstation gebracht.`,
        },  
        {   date:   `Dienstag 13.04.2004`,
            note:   `Nur 36,1° Temperatur, aber nur wegen der Medikamente.
                     Blutbild (Plättchen und rote Blutkörperchen) ist so schlecht, daß ich mit einem Krankentransport wieder nach München gebracht werde.
                     Auf der Station 3/4 bekomme ich eine Transfusion von Blutplättchen und roten Blutkörperchen.
                     Dem Antrag auf Feststellung einer Behinderung wird mit einem Grad der Behinderung von 100% stattgegeben.`,
        },         	
        {   date:   `Mittwoch 14.04.2004`,
            note:   `Habe in der Nacht auf Mittwoch stark geschwitzt mit bis zu 39,2° Temperatur und einer Art Schüttelfrost.
                     Bekomme nochmals eine Transfusion roter Blutkörperchen.`,
        },  
        {   date:   `Donnerstag 15.04.2004`,
            note:   `Konnte besser schlafen als gestern, habe aber in der früh wieder 38,7°.
                     Bekomme nochmals 2mal eine Transfusion roter Blutkörperchen.
                     Nachmittags Röntgen Torax, ob eine Lungenentzündung vorliegt; aber der Verdacht bestätigt sich nicht.
                     Habe untertags schon unter Luftknappheit zu leiden - abends habe ich wieder 39,7° Fieber und hartnäckigen Husten.`,
        },         	
        {   date:   `Freitag 16.04.2004`,
            note:   `Nacht war wieder sehr durchwachsen.
                     Vom Torax wird eine CT gemacht; dabei werden mehrere Entzündungsherde gefunden.
                     Im Krankenbett wird eine Bronchoskopie (Lungenspiegelung) unter Vollnarkose durchgeführt; in den genommenen Proben werden auch keine Keime oder Erreger gefunden; ich habe das Gefühl, die Ärzte sind etwas ratlos.`,
        },  
        {   date:   `Samstag 17.04.2004`,
            note:   `Vormittags sinkt die Temperatur von alleine wieder in normale Bereiche.
                     Bekomme etwas Cortison: Atmen fällt sehr viel leichter.`,
        },            
        {   date:   `Sonntag 18.04.2004`,
            note:   `Fühle mich sehr viel besser; habe kein Fieber mehr und kann relativ frei atmen.`,
        },  
        {   date:   `Montag 19.04.2004`,
            note:   `Fühle mich immer noch gut; vielleicht liegts auch am Cortison.
                     Mir ist schrecklich langweilig.`,
        },  	
        {   date:   `Dienstag 20.04.2004`,
            note:   `Ich muß einen Lungenfunktionstest machen: Lunge ist in Ordnung.
                     Man erklärt mir, daß die Atemnot eine Nebenwirkung des Chemomittels MTX war; das Problem ist nun, daß MTX, auf das die gesamte Chemo aufbaut, bei mir nicht mehr eingesetzt werden kann, um Spätfolgen an der Lunge zu vermeiden. Deshalb entschließen sich die Ärzte, statt der 2 fehlenden Zyklen nur noch 1 Hochdosiszyklus durchzuführen. Das ist eigentlich eine schlechte Nachricht, denn bei diesem Hochdosiszyklus werden mir meine eigenen Stammzellen (autologe Stammzelltransplantation) zurückgegeben. Dies sollte eigentlich ein Trumpf bei einem eventuellen Rezidiv sein und muß nun schon gespielt werden.
                     ==> 4. Zyklus ist fertig.`,
        },  
        {   date:   `Mittwoch 21.04.2004`,
            note:   `Mir geht's soweit gut.
                     Langeweile.`,
        },         	
        {   date:   `Donnerstag 22.04.2004`,
            note:   `Habe die ganze Nacht kein Auge zugetan: mein Zimmernachbar hatte große Probleme mit dem Kreislauf; kann man nix machen: vor einer Woche war ich noch recht schlecht beieinander!!
                     Wurde nochmals zu einer Torax-CT geschickt.
                     Darf endlich wieder heim.`,
        },  
        {   date:   `Mittwoch 28.04.2004`,
            note:   `Anreise auf Station 3/0 (Isolierstation) in ein Einzelzimmer.
                     Muß mehrere Untersuchungen erledigen, nämlich:
                     Ruhe-EKG.
                     Herz-Echo.
                     Röntgen der Nasen-Neben-Höhlen.
                     Lungenfunktionstest.
                     Röntgen des Kiefers: beinahe hätte man noch meine beiden Weisheitszähne gezogen.
                     - / 89,1kg`,
        },            
        {   date:   `Donnerstag 29.04.2004`,
            note:   `Eine weitere Untersuchung steht noch an: HNO-Bereich: meine Nasenscheidewand ist zwar furchtbar schief, aber egal.
                     1. Tag Chemo: nachmittags wird gleich mit der Hochdosischemo losgelegt; fühle mich soweit ok, habe nur ein leichtes Hitzegefühl.
                     88,0kg / 88,6kg`,
        },  
        {   date:   `Freitag 30.04.2004`,
            note:   `Nicht so gut geschlafen, da ich ständig zum Wasserlassen muß.
                     War in einer Vorlesung eines Professors als Vorführpatient; habe dabei zum 1. mal CT-Bilder von mir gesehen.
                     2. Tag Chemo: 12000 Leukos und leichtes Hitzegefühl.
                     87,9kg / 89,6kg`,
        },        	
        {   date:   `Samstag 01.05.2004`,
            note:   `3. Tag Chemo: leichtes Hitzegefühl.
                     88,8kg / 90,1kg`,
        },  
        {   date:   `Sonntag 02.05.2004`,
            note:   `4.Tag Chemo: unangenehmer Geschmack im Mund und Hitzegefühl, aber sonst alles ok.
                     88,5kg / 90,8kg`,
        }, 
        {   date:   `Montag 03.05.2004`,
            note:   `5. und letzter Tag Chemo: unangenehmer Geschmack im Mund.
                     Die letzte Chemo-Flasche endet um 1150!!!
                     88,6kg / 90,2kg`,
        },  
        {   date:   `Dienstag 04.05.2005`,
            note:   `Soweit ist noch alles ok.
                     Habe wieder Probleme mit der Sehschärfe.
                     Leichte Entzündung im rechten Ohr.
                     89,2kg / 90,9kg`,
        }, 
        {   date:   `Mittwoch 05.05.2004`,
            note:   `Habe schlecht geschlafen.
                     Um 1400 autologe Stammzelltransplantation: Dauert nur 5min.
                     Habe kurzzeitig den Geschmack von Frostschutzmittel im Mund.
                     Ansonsten geht's gut.
                     89,2kg / 90,5kg`,
        },  
        {   date:   `Donnerstag 06.05.2004`,
            note:   `Bin total platt, fühle mich sehr müde; komme kaum aus dem Bett; in der Nacht sind die Blutwerte in den Keller gerauscht.
                     Absolut kein Appetit.
                     90,6kg / 89,5kg`,
        }, 
        {   date:   `Freitag 07.05.2004`,
            note:   `Blutwerte sind weiter am Sinken.
                     sonst geht's wieder etwas besser.
                     87,7kg / -`,
        },  
        {   date:   `Samstag 08.05.2004`,
            note:   `Bin wieder so müde.
                     Nur noch 280 Leukos.
                     85,5kg / 86,9kg`,
        },        	
        {   date:   `Sonntag 09.05.2004`,
            note:   `Unter 100 Leukos.
                     Rechtes Ohr schmerzt: HNO-Arzt kommt aufs Zimmer.
                     Ziemlich müde.
                     85,2kg / 86,4kg`,
        },  
        {   date:   `Montag 10.05.2004`,
            note:   `60 Leukos.
                     Habe eine Ohrentzündung rechts.
                     Fühle mich immer noch müde.`,
        }, 
        {   date:   `Dienstag 11.05.2004`,
            note:   `Subjektiv geht's etwas besser.
                     90 Leukos.
                     Nur noch 11000 Blutplättchen: bekomme eine Transfusion.
                     84,7kg / 85,9kg`,
        },  
        {   date:   `Mittwoch 12.05.2004`,
            note:   `70 Leukos.
                     Meine Bauchhaare fangen wieder an zu sprießen.
                     Zahnfleisch beginnt anzuschwellen.
                     85,7kg / 86,3kg`,
        }, 
        {   date:   `Donnerstag 13.05.2004`,
            note:   `60 Leukos.
                     Zahnfleisch ist geschwollen.
                     85,3kg / 87,0kg`,
        },  
        {   date:   `Freitag 14.05.2004`,
            note:   `Dem Zahnfleisch get's schon wieder etwas besser.
                     120 Leukos und 13000 Blutplättchen.
                     Ohr ist immer noch entzündet.
                     86,1kg / 87,0kg`,
        },         	
        {   date:   `Samstag 15.05.2004`,
            note:   `130 Leukos und 12000 Blutplättchen.
                     Fühle mich wieder schlapper.
                     85,9kg / 86,3kg`,
        },  
        {   date:   `Sonntag 16.05.2004`,
            note:   `430 Leukos und 13000 Blutplättchen.
                     Werde sehr leicht müde.
                     84,9kg / 85,0kg`,
        }, 
        {   date:   `Montag 17.05.2004`,
            note:   `620 Leukos.
                     83,6kg / 85,9kg`,
        },  
        {   date:   `Dienstag 18.05.2004`,
            note:   `850 Leukos.
                     Ansonsten geht's mir ganz gut.`,
        },         	
        {   date:   `Mittwoch 19.05.2004`,
            note:   `1100 Leukos: das bedeutet, ich darf heute noch heim!!!`,
        },  
        {   date:   `Samstag 22.05.2004`,
            note:   `Kann mit Freunden meinen Geburtstag feiern, wenn auch nur kurz, da ich ständig müde bin. 
                     Aber macht nix!!! Die richtige Feier wird halt nachgeholt!!!`, 
        },          	
        {   date:   `Folgende Wochen:`,
            note:   `Im großen und ganzen geht's mir ganz gut; ich bin nicht belastbar und sehr schnell erschöpft; brauche viel Ruhe und Schlaf.`,
        },  
        {   date:   `Montag 24.05.2004`,
            note:   `2400 Leukos.`,
        },
        {   date:   `Mittwoch 26.05.2004`,
            note:   `5400 Leukos.`,
        },  
        {   date:   `Freitag 28.05.2004`,
            note:   `7300 Leukos.`,
        },         	
        {   date:   `Dienstag 01.06.2004`,
            note:   `5700 Leukos.`,
        },  
        {   date:   `Dienstag 08.06.2004`,
            note:   `4700 Leukos`,
        },         	
        {   date:   `Mittwoch 16.06.2004`,
            note:   `Muß nach München zur 1. Nachsorgeuntersuchung: es wird eine Blutprobe genommen und eine CT-Untersuchung gemacht.`,
        },  
        {   date:   `Donnerstag 17.06.2004`,
            note:   `Erfahre, daß alles in Ordnung ist.`,
        },        	
        {   date:   `Freitag 13.08.2004`,
            note:   `Mein Port hat sich entzündet und muß sofort entfernt werden.
                     Fahre ins Klinikum Ingolstadt, wo ich noch nachmittags operiert werde.
                     OP läuft ganz ok, auch wenn die lokale Betäubung ständig nachgespritzt werden muß.
                     Muß leider die Nacht zur Beobachtung im Klinikum bleiben.`,
        },            	
        {   date:   `Samstag 14.08.2004`,
            note:   `Die Wunde sieht gut aus und ich darf wieder heim.`,
        },           	
        {   date:   `Samstag 21.08.2004`,
            note:   `Feiere mit dem Üggi zusammen Geburtstag; war echt eine schöne Feier.
                     Bin um 04:00 Uhr morgens mit den letzten heimgegangen.
                     Leider sind die Fotos nicht jugendfrei, sonst hätte ich auch eins verlinkt ;-)`,
        },
    ]
    const timeLineData2 = [
        {   date:   `Donnerstag 02.09.2004`,
            note:   `Spiele zum 1. mal wieder Fußball, wenn auch nur 10 min. zum Schluß.`,
        },         	
        {   date:   `Montag 06.09.2004`,
            note:   `Bin zur Wiedereingliederung wieder in der Arbeit.`,
        },        	
        {   date:   `Dienstag 07.09.2004`,
            note:   `Bin heuer zum 1.mal wieder beim Friseur; ich bitte darum, nur die Spitzen zu schneiden ;-)`,
        },            	
        {   date:   `Dienstag 21.09.2004`,
            note:   `Muß zur Nachsorge wieder nach München; es wird etwas Blut entnommen und eine CT gemacht.
                     Schaue noch auf Station 3/4 vorbei und ratsche etwas mit den Schwestern.`,
        },        	
        {   date:   `Mittwoch 22.09.2004`,
            note:   `Erfahre, daß bei der CT vom 21.09. nix gefunden wurde: sowas höhrt man doch gerne, oder?`,
        },        	
        {   date:   `Montag 04.10.2004`,
            note:   `Die Arbeitswelt hat mich wieder; seit heute muß ich wieder ganz normal zur Arbeit gehen. Aber nach so einer Scheiße ist man ganz froh, daß es wieder "normal" weitergeht!`,
        },        	
        {   date:   `Dienstag 12.10.2004`,
            note:   `Nehme zum 1. mal heuer wieder mein Luftgewehr zur Hand, um wieder etwas zu trainieren; naja, ich war schon mal besser , aber das Alter ... ;-)`,
        },                   	
        {   date:   `Sonntag 07.11.2004`,
            note:   `Habe nachmittags noch Fußball gespielt; bekomme abends leichte Bauchschmerzen, die sich im Laufe der Nacht verschlimmern.
                     Da gehen einem die schlimmsten Gedanken durch den Kopf; an Schlaf ist da nicht zu denken.`,
        },        	
        {   date:   `Monatg 08.11.2004`,
            note:   `Bin vormittags gleich zum Arzt: mit Ultraschall ist nichts zu erkennen und der größte Schmerz ist im Bereich des Blinddarms. Wir beschließen, noch eine Nacht zu warten, bevor weitere Schritte eingeleitet werden.
                     In der Nacht auf Dienstag wird's Gott sei Dank wieder besser.`,
        },          	
        {   date:   `Mittwoch 10.11.2004`,
            note:   `Die Schmerzen sind wieder vollständig abgeklungen.
                     Was bleibt ist ein schaler Nachgeschmack, daß es auch ein Rückfall hätte sein können; ich muß zugeben, daß mir, auf gut bayrisch, der Arsch ganz schön auf Grund gelaufen ist!!`,
        },        	
        {   date:   `Mittwoch 08.12.2004`,
            note:   `Muß zur Nachsorge wieder nach München; es wird etwas Blut entnommen und eine CT gemacht.
                     Schaue noch auf den Stationen 3/4 und 3/0 vorbei und ratsche etwas mit den Schwestern.`,
        },          	
        {   date:   `Donnerstag 09.12.2004`,
            note:   `Erfahre, daß bei der CT vom 08.12. nix gefunden wurde: bin irgendwie doch sehr erleichtert.`,
        },        	
        {   date:   `Donnerstag 17.03.2005`,
            note:   `Muß zur Nachsorge wieder nach München; es wird etwas Blut entnommen und eine CT gemacht.`,
        },           	
        {   date:   `Freitag 18.03.2005`,
            note:   `Erfahre, daß bei der CT vom 17.03. auch weiterhin nix gefunden wurde.
                     Das Intervall der Nachsorgeuntersuchungen wird auf 4 Monate erweitert.
                     In Zukunft wird dann auch die CT im Wechsel mit Ultraschall und Röntgen gemacht, um die Strahlenbelastung etwas zu senken.`,
        },        	
    ]

    return (
        <div className="BodyText">
            <p>
                Tja, wann, wo und wie soll ich beginnen?
            </p>
            <p>
                Ich dachte mir, daß es wohl am Besten ist, wenn ich in chronologischer Reihenfolge stichpunktartig die ganze Geschichte darstelle. Schließlich soll das ganze ja kein Abenteuerroman werden!
                Wie ich schon erwähnt habe, bin ich Laie. Deshalb bitte ich darum, mir kleinere Fehler und Ungenauigkeiten zu verzeihen.
            </p>
            <p>
                Teilweise habe ich zwei Gewichtswerte angegeben. Dabei bedeutet der 1. Wert mein Gewicht am Morgen und der 2. Wert das Gewicht am Abend.
                Daran kann man sehen, wie durch Infusionen usw. erhebliche Mengen Flüssigkeit in den Körper gepumpt wurde bzw. wie das Körpergewicht generell durch die Erkrankung (Durchfall, Übelkeit, Appetitlosigkeit, Schwäche) schwanken kann.
            </p>
            <p>
                Um noch einige Informationen zu meiner Person zu geben: ich war vor der Erkrankung durch das Lymphom ca. 88kg schwer.
                Für meine Verhältnisse war ich relativ sportlich und fit, habe seit ca. 8 Jahren nicht mehr geraucht und mein Alkoholkonsum hielt sich auch in Grenzen.
                Wobei das mit dem Alkohol ja immer so eine Sache ist. Da wird man schnell in eine Schublade geschoben mit einem großen roten A vorne drauf.
                Auf alle Fälle meinten die Ärzte, daß sie mir nicht sagen können, was denn nun bei mir der Auslöser gewesen war. 
                Das ist insofern schlecht, da ich jetzt nicht so richtig sagen kann, was ich denn an meinem Dasein ändern sollte, um einen Rezidiv zu vermeiden. 
                Nun kann ich nur so weiterleben wie vorher. Was sollte ich auch anders machen?
            </p>
            <br /><br />
            <TimeLineTable content={timeLineData1} />
            <br /><br />
            <p>
                    Bleibt zum Schluß noch die Frage, wie's weitergehen soll!?! 
                    Zunächst einmal muß ich mitte September zur nächsten Nachsorgeuntersuchung. Zudem werde ich Anfang September langsam wieder ins Arbeitsleben eingegliedert. 
                    Ansonsten fühle ich mich gut. Natürlich bin ich noch nicht so belastbar wie früher, aber ich denke, das bessert sich auch noch im Laufe der Zeit. 
                    Ich habe wieder begonnen, etwas Sport zu treiben und versuche auch sonst, aktiv zu sein.`,
            </p>
            <br /><br />
            <TimeLineTable content={timeLineData2} />
            <br /><br />
            <p>
                Was auch nicht zu unterschätzen ist: man darf nicht ständig daran denken, was hätte passieren können bzw. was noch passieren kann. 
                Denn dann müßte man mit soviel Angst durchs Leben gehen, daß man gleich das Handtuch werfen kann. Und das habe ich bestimmt nicht vor!!!
            </p>
            <p>
                So, inzwischen ist das Jahr 2007 schon wieder ein paar Tage alt. Die Nachsorgeintervalle betragen zur Zeit 6 Monate im Wechsel Röntgen und CT. 
                Auch weiterhin gibt es keine Anzeichen, die darauf schließen lassen, daß da neuer Ungemach auf mich zukommt. 
                Ich möchte deshalb diese Seiten zunächst einmal abschließen. Natürlich würde ich mich über weitere Emails sehr freuen.
            </p>
            <p>
                2017 ist gerade angebrochen und ich hatte endlich mal die Zeit und Muse, das Ganze etwas zu aktualisieren. 
                Mich gibt's nach wie vor. Also keine Angst - bei Fagen einfach eine <Link to="/contact">Mail</Link> schreiben!
            </p>
        </div>
    );
}
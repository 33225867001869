import Nav from 'react-bootstrap/Nav';
import { NavLink } from 'react-router-dom';

export default function Header() {
  return (
    <div>
      <Nav.Item className="HeaderAll">
        <Nav.Link as={NavLink} to="/">
          <span className="Header1">Burkitt-Lymphom</span>
          <br />
          <span className="Header2">Ein Erfahrungsbericht für Betroffene und Interessierte</span>
        </Nav.Link>
      </Nav.Item>
    </div>
  );
}
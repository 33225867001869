import Button from 'react-bootstrap/Button';
import { useState } from "react";

export default function CopyToClipboard ({ copyText }) {

  // https://blog.logrocket.com/implementing-copy-clipboard-react-clipboard-api/

  const [isCopied, setIsCopied] = useState();

  const unsecuredCopyToClipboard = (text) => { 
    const textArea = document.createElement("textarea"); 
    textArea.value=text; document.body.appendChild(textArea); 
    textArea.focus();
    textArea.select(); 
    try{
      document.execCommand('copy')}
    catch(err)
      {console.error('Unable to copy to clipboard',err)}
    document.body.removeChild(textArea)};


  async function copyTextToClipboard(text) {
    if (window.isSecureContext && navigator.clipboard) {
      return await navigator.clipboard.writeText(text);
    } else {
      return await unsecuredCopyToClipboard(text)
    }
  }

  // onClick handler function for the copy button
  const handleCopyClick = () => {
    // Asynchronously call copyTextToClipboard
    copyTextToClipboard(copyText)
      .then(() => {
        // If successful, update the isCopied state value
        setIsCopied(true);
        setTimeout(() => {
          setIsCopied(false);
        }, 1500);
      });
  }


  return (
    <div style={CopyToClipboardStyle.divStyle}>
      <span style={CopyToClipboardStyle.spanStyle}>{copyText}</span>
      <Button 
        variant="primary" size="sm"
        disabled={isCopied}
        onClick={handleCopyClick}>
        <span>{isCopied ? 'Kopiert!' : 'Kopieren'}</span>
      </Button>
    </div>
  );
}


// Huer zu Übungszwecken das CSS in der Component
// ACHTUNG: 
// - Die Attribute müssen in "" sein 
// - zusammengesetzte Vorgaben (z.B. margin-top) müssen CamelCase geschrieben werden
// - Zum Trennen ein Komma statt Strickpunkt

const CopyToClipboardStyle = {
  divStyle: {
    display: "flex",
    marginTop: "10px",
    fontWeight: "bolder",
  },
  
  spanStyle: {
    marginRight: "30px",
  }
}
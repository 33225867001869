import React from 'react';
import ChemoAccordion from '../components/ChemoAccordion';

import chemo1 from '../pictures/chemo/chemo1.jpg';
import chemo2 from '../pictures/chemo/chemo2.jpg';
import chemo3 from '../pictures/chemo/chemo3.jpg';
import chemo4 from '../pictures/chemo/chemo4.jpg';
import chemo5 from '../pictures/chemo/chemo5.jpg';


export default function ChemoPage() {
    
    const chemoData = [
        {
            id: 1,
            infoHeader: '1. Chemo: 22.01. bis 27.01.04',
            pictureToShow: chemo1,
        },        
        {   
            id: 2,
            infoHeader: '2. Chemo: 12.02. bis 17.02.04',
            pictureToShow: chemo2,
        },
        {   
            id: 3,
            infoHeader: '3. Chemo: 08.03. bis 12.03.04',
            pictureToShow: chemo3,
        },
        {   
            id: 4,
            infoHeader: '4. Chemo: 29.03. bis 03.04.04',
            pictureToShow: chemo4,
        },
        {   
            id: 5,
            infoHeader: '5. Chemo: 29.04. bis 03.05.04',
            pictureToShow: chemo5,
        },
        ]

    const listChemoData = chemoData.map(chemo => <ChemoAccordion key={chemo.id} data={chemo} />);

    return (
        <div className="BodyText">
            <p>
                Im Laufe meiner Behandlung habe ich insgesamt 5 Chemobehandlungen erhalten.<br />
                Damit ich weiß, was da so alles auf mich zukommt, habe ich mir Kopien der Chemopläne geben lassen.<br />
                Um die Blätter hier veröffentlichen zu können, habe ich sie eingescannt. Ich hoffe, daß die Qualität trotzdem noch ausreichend gut genug ist.
            </p>
            <br />
            {listChemoData}
        </div>
    );
}
import React from 'react';
import { Link } from 'react-router-dom';
import GuestBookEntries from '../components/GuestBookEntries'

export default function GuestbookPage() {

    return (
        <div className="BodyText">
            <p>
                Wenn jemand eine Nachricht (positiv wie negativ) hinterlassen möchte, so kann er das Gästebuch verwenden oder mir direkt eine <Link to="/contact">Mail</Link> schicken.
            </p>
            <p>
                Leider mußte ich zwischenzeitlich mit diesen Seiten umziehen, sodass alte Einträge nicht mehr angezeigt werden.
                Finde ich sehr schade, aber mir fehlte einfach das Wissen, wie ich das hätte verhindern können.<br />
                Aber ich hoffe jetzt einfach auf ein paar neue Zeilen von euch... dann ist's auch nicht so schlimm!
            </p>
            <p>
                Also, schreibt doch einfach etwas, ich würde mich freuen!!!
            </p>
            <GuestBookEntries />
        </div>
    );
}


import React, { useState } from "react";
//import Button from 'react-bootstrap/Button';

export default function PictureSlider( {pictures} ) {
    
    const [index, setIndex] = useState(0);

    const slideRight = () => { 
        setIndex((index + 1) % pictures.length);
    };

    const slideLeft = () => {
        const nextIndex = index - 1;
        if (nextIndex < 0) {
            setIndex(pictures.length - 1); // returns last index of pictures array if index is less than 0
        } else {
        setIndex(nextIndex);
        }
    };

    return (
        pictures.length > 0 && (
            <div className = "PictureSliderDiv">
                <div>
                    <button
                        className = "PictureSliderButton"
                        variant="primary" size="lg"
                        onClick={slideLeft}>
                        {"<"}
                    </button>
                    <button 
                        className = "PictureSliderButton"
                        variant="primary" size="lg"
                        onClick={slideRight}>
                        {">"}
                    </button>
                </div>
                <img src={pictures[index].pictureToShow} alt={index} />
                <h5 className="PictureSliderCaption">{pictures[index].caption}</h5>
            </div>
        )        
    );
};

import Accordion from 'react-bootstrap/Accordion';

export default function ChemoAccordion( {data} ) {
  
    return (
      <div>
        <Accordion>
          <Accordion.Item eventKey="0">
            <Accordion.Header>{data.infoHeader}</Accordion.Header>
            <Accordion.Body>
              <img src={data.pictureToShow} alt={"Bild: " + data.infoHeader}></img>
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      </div>
    );
  }
//import Table from 'react-bootstrap/Table';

export default function TimeLineTable( {content}) {

    const tableContentList = content.map(tableContent => 
        <tr key = {tableContent.date} className="TimeLineTableRow">
            <td className="TimeLineTableElement">{tableContent.date}</td>
            <td className="TimeLineTableElement">{tableContent.note}</td>
        </tr>
    )

    return (
        <table className="TimeLineTable">
            <thead>
                <tr>
                    <th className="TimeLineTableHead">Datum</th>
                    <th className="TimeLineTableHead">Notizen / Anmerkungen / Gedanken</th>
                </tr>
            </thead>
            <tbody>
                {tableContentList}
            </tbody>
        </table>
  );
}

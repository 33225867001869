import React from 'react';
import PictureSlider from '../components/PictureSlider';

import bild1 from '../pictures/foto/bild1.jpg';
import bild2 from '../pictures/foto/bild2.jpg';
import bild3 from '../pictures/foto/bild3.jpg';
import bild4 from '../pictures/foto/bild4.jpg';
import ct1 from '../pictures/foto/ct1.jpg';
import ct2 from '../pictures/foto/ct2.jpg';
import ct3 from '../pictures/foto/ct3.jpg';
import ct4 from '../pictures/foto/ct4.jpg';
import namensschild from '../pictures/foto/namensschild.jpg'
import port1 from '../pictures/foto/port1.jpg';
import port2 from '../pictures/foto/port2.jpg';
import zvk from '../pictures/foto/zvk.jpg'

export default function PicturesPage() {

    const pictureData = [
        {
            caption: 'Eines der ersten Photos: In München auf Station 3/4. Man erkennt den ZVK am Hals und den geschienten Finger.',
            pictureToShow: bild1,
        },
        {
            caption: 'Das Namensschild vom 1. Zyklus habe ich aus Sentimentalität aufgehoben.',
            pictureToShow: namensschild,
        },
        {
            caption: 'Beim 1. Zyklus hatte ich einen ZVK (zentraler Venenkatheter) bekommen. Der Venenkatheter ist gekürzt.',
            pictureToShow: zvk,
        },
        {
            caption: 'Ich hab mal eine Skizze eines aufgeschnittenen Ports erstellt (bekam ich vor dem 2. Zyklus implantiert).',
            pictureToShow: port1,
        },
        {
            caption: 'CT-Bild: Schnitt durch den Bauchraum zwischen Nabel und Rippenbogen (Zwischenstaging vom 05.03.04).',
            pictureToShow: ct1,
        },
        {
            caption: 'CT-Bild: Schnitt durch das Herz (Zwischenstaging vom 05.03.04).',
            pictureToShow: ct3,
        },
        {
            caption: 'CT-Bild: Schnitt durch die Lunge (Zwischenstaging vom 05.03.04).',
            pictureToShow: ct4,
        },
        {
            caption: 'CT-Bild: Schnitt durch den Kopf unterhalb der Augen (Zwischenstaging vom 05.03.04).',
            pictureToShow: ct2,
        },
        {
            caption: '4. Zyklus auf der Station 3/4. Begeisterung schaut eigentlich anders aus.',
            pictureToShow: bild3,
        },
        {
            caption: 'Beim Endspurt auf der Station 3/0. Da darf man keine Angst vor Infusionsgeräten haben ;-)',
            pictureToShow: bild2,
        },
        {
            caption: 'Mein explantierter Port. Zum Vergleich habe ich ein 2 €-Stück dazugelegt. Der Venenkatheter ist gekürzt.',
            pictureToShow: port2,
        },
        {
            caption: 'So schaute ich dann im Februar 2005 aus. Löckchen hatte ich vor der Chemo nicht! ;-)',
            pictureToShow: bild4,
        },        
        ]

    return (
        <div className="BodyText">
            <p>
                Natürlich habe ich auch einige Photos, CT-Aufnahmen und Skizzen zusammengestellt.<br />
                Allerdings habe ich keine Photos, als ich nach München kam. Das wäre sehr interessant, denn dann könnte man einen Vorher- Nachher-Vergleich machen.<br />
                Aber was soll's.
            </p>
            <PictureSlider pictures = {pictureData} />
        </div>
    );
}
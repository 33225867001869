import React, { useState, useRef, useEffect } from "react";
import { Col, Row, Form, Button, Spinner } from "react-bootstrap";
import axios from "axios";

export default function GuestBookEntries() {

    // !
    // ! ACHTUNG: VOR UPLOAD RICHTIGE ZEILEN KOMMENTIEREN !
    // !
    const BASE_API_URL = "https://berni75.lima-city.de/php/";    
    //const BASE_API_URL = "http://mitch.bplaced.net/php/";       
    //const BASE_API_URL = "http://localhost:3000/php/";                                          

    const initialEntries = [{}];

    const [entries, setEntries] = useState(initialEntries);                                     // Gästebuch-Einträge
    const [formErrors, setFormErrors] = useState( {guestname:"", feedback: ""} );               // Fehler bei der Eingabe, z.B. zu wenig Zeichen
    const [apiError, setApiError] = useState("");                                               // Fehler mit axios Server-Anbindung
    const [isLoading, setIsLoading] = useState(true);                                           // Laden der Gästbuch-Einträge aktiv
    const [isWriting, setIsWriting] = useState(false);                                          // Schreiben eines neuen Eintrags aktiv
    const [disableSubmit, setDisableSubmit] = useState(false);                                  // Button sperren bei Server-Problem

    const [timeStart, setTimeStart] = useState(0);

    const guestnameField = useRef();
    const feedbackField = useRef();
    const botCheck = useRef();
    const botCheck2 = useRef();                 // Der unsichtbare BotCheck der nicht ausgewählt werden darf

    useEffect(() => {
        setTimeStart(new Date().getTime());        // Zeit beim Aufrufen der Seite
    },[])

    useEffect(() => {                                                                           // gespeicherte Einträge werden geladen und angezeigt
        (async () => {
            let savedEntries;
            let axiosError;
            try {
                let response = await axios.get(BASE_API_URL+"guestbook_get.php");
                savedEntries = response.data;
                axiosError="";
            } catch (error) {
                savedEntries = initialEntries;
                axiosError=`Es konnten keine Daten abgerufen werden! ${error}`;                
            }
            setEntries(savedEntries);
            setIsLoading(false);
            setApiError(axiosError);
        })(); 
    },[])    // leeres Array bedeutet: 'run once'. Die gespeicherten Einträge werden nur einmalig geladen um neue Einträge nicht zu überschreiben


    // Event-Handler für den "Abschicken"-Button
    // Prüfung ob alles richtig ausgefüllt ist
    const handleSubmit = event => {
        event.preventDefault();
        
        let timeSubmit = new Date().getTime();
        let time2Submit = timeSubmit - timeStart;           // Zeit seit Aufrufen der Seite in ms; falls zu kurz => Bot-Alarm
        
        let name = guestnameField.current.value;
        let feedback = feedbackField.current.value;
        let jsonFeedback = feedback;                        // für JSON müssen Zeilenumbrüche separat behandelt werden
        
        const errors = {};
        const newEntry = {};                                 // Eintrag für direkte Ausgabe
        const newJSON = {};                                  // Eintrag für Server im JSON-Format mit ersetzten Sonderzeichen

        // Fehler-Handling
        if (name.length < 1) {
            errors.guestname = "Bitte gib deinen Namen an.";
        } else if (name.includes("http")) {
            errors.guestname = "Ungültige Eingabe - Abwehr von Spam-Bots."
        } else {
            name = name.replaceAll(/[\\]/g,'|');                    // für JSON "\"" durch "|"" ersetzen
            name = name.replaceAll(/["]/g,'\'');                    // für JSON " durch ' ersetzen
            errors.guestname = "";
        }
        if (feedback.length < 1) {
            errors.feedback = "Bitte gib erst dein Feedback ein.";
        } else if (feedback.includes("http")) {
            errors.feedback = "Ungültige Eingabe - Abwehr von Spam-Bots."
        }
        else {
            feedback = feedback.replaceAll(/["]/g,'\'');           // für JSON " durch ' ersetzen
            jsonFeedback = feedback.replaceAll("\n", "\\n");       // nur für JSON ersetzen, nicht für direkte Ausgabe im Browser; andere "\"" werden akzeptiert
            jsonFeedback = jsonFeedback.replaceAll(/[\\]/g, '|');  // "\" führt in Kombination mit Sonderzeichen zu Problemen
            errors.feedback = "";
        }
        if (!botCheck.current.checked || botCheck2.current.checked) {
            errors.bot = "Bitte bestätige erst, dass du kein Bot bist."
        } else {
            errors.bot = "";
        }
        
        setFormErrors(errors);

        if (errors.guestname.length > 0 || errors.feedback.length > 0) {
            console.log("Fehler in Eingabemaske");
            return
        }
        if (errors.bot.length > 0 || time2Submit < 2000) {
            console.log("Spam-Bot Alarm!!!")
            return
        }

        setIsWriting(true);

        // Ausgabe im Browser aktualisieren
        newEntry.id = new Date().getTime();                                                     // die aktuelle eindeutige Zeit wird gleich zur ID
        newEntry.name = name;
        newEntry.text = feedback;
        const newEntries = [...entries, newEntry];                                              // neuen Eintrag an vorhandene anhängen
        setEntries(newEntries);                                                                 // und dem State zuweisen damit neu gerendert wird

        // Objekt als JSON für Ausgabe an Server
        newJSON.id = newEntry.id;
        newJSON.name = name;
        newJSON.text = jsonFeedback;
        
        // Neuen Eintrag zum Server schicken
        (async() => {
            let axiosError;
            try {
                let response = await axios.post(BASE_API_URL+"guestbook_post.php", newJSON);
                axiosError="";
            } catch (error) {
                axiosError=`Fehler beim Erstellen des neuen Eintrags! ${error}`;
                console.log("Post Catch");
            }
            setApiError(axiosError);        

            // Eingebafelder werden nur bei fehlerfreier Übertragung gelöscht
            if (axiosError.length === 0) {
                guestnameField.current.value = "";
                feedbackField.current.value = "";
                botCheck.current.checked = false;
                setIsWriting(false);                                                            // Bei fehlerfreier Übertragung wird Button wird wieder freigegeben wenn 
            }
        })();
    }

    // Sperren des Abschicken-Buttons, falls ein Problem mit der Server-Kommunikation vorliegt oder solange ein neuer Eintrag geschrieben wird
    useEffect(() => {
        (apiError.length > 0) ? setDisableSubmit(true) : setDisableSubmit(false);
        (isWriting === true) ? setDisableSubmit(true) : setDisableSubmit(false);
    },[apiError, isWriting])
    

    // Sortieren der Gästebuch-Einträge
    let sortedEntries = entries.slice(0);           // Kopie der Gästebuch-Einträge
    sortedEntries.sort(function(a,b) {
         return b.id - a.id;                         // Sortieren nach ID == Datum/Uhrzeit
    });
        
    
    const listEntries = sortedEntries.map((entry) => 
        <div key={entry.id}>
            <div className = "GuestBookEntryDiv">
                <h6 className = "GuestBookEntryH6">{entry.name}, {IdToDatum(entry.id)}</h6>
                <p className = "GuestBookEntryText">{entry.text}</p>
            </div>
        </div>
    );


    return (
        <div>
            <Form onSubmit = {handleSubmit} className="GuestBookForm">
                <Form.Group controlId="name" className="InputField">
                    <Form.Label>Dein Name:</Form.Label>
                        <Form.Control 
                            type="text"
                            placeholder="Name"
                            maxLength="30"
                            ref={guestnameField}
                    />
                    <Form.Text className="text-danger">{formErrors.guestname}</Form.Text>
                </Form.Group>
                <br />
                <Form.Group controlId="feedback" className="FeedbackField">
                    <Form.Label>Dein Feedback:</Form.Label>
                        <Form.Control
                            as="textarea"
                            rows={3}
                            placeholder="Feedback"
                            maxLength="1000"
                            ref={feedbackField} 
                        />
                    <Form.Text className="text-danger">{formErrors.feedback}</Form.Text>
                </Form.Group>
                <br />
                <Row>
                    <Col>
                    <Form.Group controlId="botcheck" className="BotcheckBox">
                        <label>Bitte bestätige vor dem Abschicken, dass du ein Mensch bist</label>
                        <Form.Switch
                            className="BotSwitch"
                            id="custom-switch"
                            ref={botCheck}>
                        </Form.Switch>
                        <Form.Text className="text-danger">{formErrors.bot}</Form.Text>
                    </Form.Group>
                    </Col>
                    <Col xs={0}>
                    <Form.Group controlId="botcheck2" className="BotcheckBox2">
                        <label> </label>
                        <Form.Switch
                            id="custom-switch"
                            ref={botCheck2}>
                        </Form.Switch>
                    </Form.Group>
                    </Col>
                </Row>
                <br />                
                <Button variant="primary" className="GuestBookSubmitButton" type="submit" disabled={disableSubmit}>
                    Abschicken
                </Button>
                <br /><br />
            </Form>
        
            {(isLoading) ? ( 
                <div>
                    <h4>Daten werden geladen!</h4>
                    <Spinner animation="border" variant="primary" role="status" />                    
                </div>) : null
            }
            {(apiError.length > 0) ? (
                <div>
                    <h4>{apiError}</h4>
                </div>) : null
            }
            {(!isLoading && apiError.length===0) ? (
                <div>
                    <h3 className="GuestBookEntriesHeader">Bisherige Einträge:</h3>
                    {listEntries}
                </div>) : null
            }
        </div>
    )
}

function IdToDatum(id) {
    let datum;
    if(id === 0){               // wenn noch kein Eintrag vorhanden ist, wird "0" vom Server gesendet
        datum = new Date();
    }
    else {
        datum = new Date(id);
    }
    const datumString = datum.getDate().toString()+"." + (datum.getMonth()+1).toString() + "." + datum.getFullYear().toString();
    return datumString;
}
import './App.css';

import { BrowserRouter, Routes, Route } from 'react-router-dom';

import Header from './components/Header';
import Navigation from './components/Navigation';

import StartPage from './pages/StartPage';
import TimelinePage from './pages/TimelinePage';
import PicturesPage from './pages/PicturesPage';
import ChemoPage from './pages/ChemoPage';
import GuestbookPage from './pages/GuestbookPage';
import ContactPage from './pages/ContactPage';

function App() {
  return (
    <div>
      <BrowserRouter>
        <Header />
        <Navigation />
        <Routes>
          <Route path="/timeline" element={<TimelinePage />} />
          <Route path="/pictures" element={<PicturesPage />} />
          <Route path="/chemo" element={<ChemoPage />} />
          <Route path="/guestbook" element={<GuestbookPage />} />
          <Route path="/contact" element={<ContactPage />} />
          <Route path="*" element={<StartPage />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;

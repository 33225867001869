import React from 'react';
import { Link } from 'react-router-dom';


export default function StartPage() {
    return (
        <div className="BodyText">
            <p>
                Tja, das Thema für diese Seiten lautet, wie der Titel schon verrät, <b>Burkitt-Lymphom</b>.
            </p>
            <p>
                An dieser Tumorart erkrankte ich Ende 2003 und war bis Mai 2004 in Behandlung. Aber wozu ich diese Zeilen schreibe? 
                Es gibt soviele Seiten mit hochwissenschaftlichen Abhandlungen über Krebs oder Beschreibungen der einzelnen Tumorerkrankungen 
                auch für Laien, so daß ich mir diese Arbeit bestimmt hätte sparen können.
                Aber ich dachte mir, daß den einen oder anderen doch interressiert, was der Betroffene dazu zu sagen hat und wie es ihm ergangen ist. 
                Es versteht sich von selbst, daß ich hier keine medizinischen Ratschläge geben kann und will. 
            </p>
            <p>
                Ich möchte lediglich meinen Krankheitsverlauf mit meiner individuellen Behandlung darstellen.
                Dazu habe ich eine Art Tagebuch geführt, in dem ich Notizen zur Behandlung und zu meinem Befinden gemacht habe, nachzulesen unter <Link to="/timeline">Krankheitsverlauf</Link>.
                Für den, den's interressiert und der damit etwas anfangen kann, habe ich auch die Pläne für meine <Link to="/chemo">Chemotherapien</Link> eingescannt.
                Ein paar Bilder habe ich auch noch vorbereitet, u.a. auch Aufnahmen von CT-Untersuchungen.
            </p>
            <p>
                Ich hoffe, daß meine Ausführungen dem einen oder anderen geben, wonach er gesucht hat.
            </p>
            <br /><br /><br />
            <p>
                Aber vor allem wünsche ich mir und euch Gesundheit!!
                <br /><br />
                Denn, um meinen Onkel zu zitieren:
                "Die beste Krankheit und das schönste Krankenhaus zusammen sind trotzdem SCHEIßE!!!" 
            </p>
            <br /><br /><br />
            <p>
                berni
            </p>
        </div>
    );
}
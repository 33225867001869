import React from 'react';
import CopyToClipboard from '../components/CopyToClipboard'
import { BsMailbox } from "react-icons/bs";

export default function ContactPage() {
    
    const MailAdress = "berni@burkitt-lymphom.de"

    return (
        <div className="BodyText">
            <p>
                Ich hoffe mal, daß ich euch einen kleinen Einblick in die Krankheit und deren Behandlung gegeben habe.
            </p><p>
                Natürlich sind meine Ausführungen weder medizinisch völlig korrekt noch erhebe ich Anspruch auf Vollständigkeit.<br />
                Es soll wirklich nur für Betroffene und deren Verwandte/Freunde eine Möglichkeit sein, sich grob zu informieren und zu sehen, wie es anderen Betroffenen ergangen ist.
            </p><p>            
                Sollte noch jemand Fragen haben, so bin ich gerne bereit, diese nach Möglichkeit zu beantworten.
                Also schreibt mir doch einfach !!!"
            </p>
            <h2><BsMailbox /></h2>
            <CopyToClipboard copyText={MailAdress} />

            <br /><br /><br /><br />
            <p>
                Im Nachhinein möchte ich mich natürlich bei einigen Leuten für ihre Unterstützung und Anteilnahme während meiner Erkrankung und Genesung bedanken.<br />
                An 1. Stelle stehen meine Mutter und mein Vater, ohne deren Hilfe ich sicherlich nicht so schnell und gut wieder auf den Beinen wäre.<br />
                Außerdem bedanke ich mich auch beim meiner Familie, allen Freunden und Bekannten, die mich besucht, angerufen oder auch nur an mich gedacht haben.<br /> 
            </p>
            <p>
                Und zu guter letzt möchte ich mich ganz besonders bei den Schwestern und Pflegern des KKH Eichstätt und den Teams von der Station 3/4 und 3/0 an der TU rechts der Isar bedanken, 
                die durch ihre menschliche und fachliche Zuwendung und Arbeit ebenfalls einen sehr großen Anteil an meiner Gesundung einnehmen.
                Natürlich nicht zu vergessen die Ärzte, die mit ihrem Wissen und Können diese Zeilen überhaupt erst ermöglicht haben!!
            </p>
            <br /><br />
            <p className="Merci">
                Merci an euch alle !!!
            </p>
        </div>
    );
}